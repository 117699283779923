import React from "react";
import Head from "next/head";
import { ThemeProvider } from "@material-tailwind/react";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from "react-hot-toast";
import { useRouter } from "next/router";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { persistor } from "@/globalStates/store";
import { formatCategory } from "../../../utils/common-functions";

const Layout = ({
  children,
  obj,
  hostname,
  pageTitle,
  metaDescription,
  metaKeywords,
  countryName,
  countryTranslations,
}) => {
  const router = useRouter();

  let url,
    parts,
    surl,
    parts2 = [];
  url = router.asPath;
  parts = url.split("/");
  surl = parts[1];
  const fullUrl = typeof window !== "undefined" ? window.location.href : "";

  // let Url = fullUrl;
  let Url = obj;

  // Split the hostname by dots
  const hostnameParts = Url.split(".");
  let newUrl = "";
  if (hostnameParts.length > 2) {
    hostnameParts.shift();

    // Join the remaining parts back to form the new URL
    newUrl = hostnameParts.join(".");
  } else {
    newUrl = Url;
  }

  let canonicalUrl = fullUrl;
  let parts1 = canonicalUrl.split("//"); // Split the URL at the double slashes
  if (parts1.length === 2) {
    parts2 = parts1[1].split(".");
    canonicalUrl = `${parts1[0]}//ar.${parts2[1]}.${parts2[2]}`;
  }

  let arr = [
    "ar",
    "bn",
    "de",
    "es",
    "fr",
    "hi",
    "ja",
    "ko",
    "pa",
    "pt",
    "ru",
    "ta",
    "ur",
    "zh",
  ];

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={metaKeywords}></meta>
        <link rel="canonical" href={Url} />
        {arr.map(
          (item, index) =>
            item != hostname && (
              <link
                rel="alternate"
                key={index}
                href={`https://${item}.${newUrl}`}
              ></link>
            )
        )}
        {hostname && <link rel="alternate" href={`https://${newUrl}`}></link>}
      </Head>
      <Toaster />

      <PersistGate persistor={persistor}>
        <ThemeProvider>
          <Header
            countryName={countryName}
            countryTranslations={countryTranslations}
          />
          <main>{children}</main>
          <Footer
            countryName={countryName}
            countryTranslations={countryTranslations}
          />
        </ThemeProvider>
      </PersistGate>
    </>
  );
};

export default Layout;
