const locals = {
  en: () => import("./src/locals/en.json").then((r) => r.default),
  hi: () => import("./src/locals/hi.json").then((r) => r.default),
  es: () => import("./src/locals/es.json").then((r) => r.default),
  ar: () => import("./src/locals/ar.json").then((r) => r.default),
  pt: () => import("./src/locals/pt.json").then((r) => r.default),
  zh: () => import("./src/locals/zh.json").then((r) => r.default),
  ko: () => import("./src/locals/ko.json").then((r) => r.default),
  ta: () => import("./src/locals/ta.json").then((r) => r.default),
  de: () => import("./src/locals/de.json").then((r) => r.default),
  bn: () => import("./src/locals/bn.json").then((r) => r.default),
  ru: () => import("./src/locals/ru.json").then((r) => r.default),
  ja: () => import("./src/locals/ja.json").then((r) => r.default),
  pa: () => import("./src/locals/pa.json").then((r) => r.default),
  fr: () => import("./src/locals/fr.json").then((r) => r.default),
  ur: () => import("./src/locals/ur.json").then((r) => r.default),
};

export const getLocals = async (lang) => {
  const getLocale = locals[lang] || locals.en; // default to English if language not found
  const locale = await getLocale();
  return locale;
};
