import React, { useEffect, useState } from "react";
import Image from "next/image";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";
import { useRouter } from "next/router";
import Link from "next/link";
import { getLocals } from "../../../getLocal";
import { hostNameFunction } from "../../../utils/host-function";
import { Select, Option } from "@material-tailwind/react";

export default function Header({ countryName, countryTranslations }) {
  const siteName = process.env.NEXT_PUBLIC_SITENAME;
  // Convert to uppercase and handle hyphen formatting
  const formattedSiteName = siteName ? siteName.toUpperCase() : "";
  const [open, setOpen] = useState(false);
  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);
  const [selectedLang, setSelectedLang] = useState("");
  const languages = [
    { value: "www", label: "English" },
    { value: "ar", label: "Arabic" },
    { value: "bn", label: "Bengali" },
    { value: "de", label: "German" },
    { value: "es", label: "Spanish" },
    { value: "fr", label: "French" },
    { value: "hi", label: "Hindi" },
    { value: "ja", label: "Japanese" },
    { value: "ko", label: "Korean" },
    { value: "pa", label: "Punjabi" },
    { value: "pt", label: "Portuguese" },
    { value: "ru", label: "Russian" },
    { value: "ta", label: "Tamil" },
    { value: "ur", label: "Urdu" },
    { value: "zh", label: "Chinese" },
  ];

  const [localizedStrings, setLocalizedStrings] = useState();
  const router = useRouter();
  let formattedCountryName = countryName
    ? countryName.toLowerCase().replace(/ /g, "-")
    : "default-country"; // Provide a default value
  console.log("formattedCountryName", formattedCountryName);

  useEffect(() => {
    const fetchLocalizedStrings = async (lang) => {
      const strings = await getLocals(lang);
      setLocalizedStrings(strings);
    };

    const handleRouteChange = (url) => {
      const lang = hostNameFunction(url);
      setSelectedLang(lang);
      fetchLocalizedStrings(lang);
    };

    // const handleRouteChange = (url) => {
    //   console.log("url", url);
    //   const lang = hostNameFunction(url);
    //   console.log("lang", lang);

    //   if (lang) {
    //     setSelectedLang(lang);
    //     fetchLocalizedStrings(lang);
    //   }
    // };

    // Initial load
    handleRouteChange(window.location.href);

    // Listen for URL changes
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  // const handleValueChange = (value) => {
  //   setSelectedLang(value);
  //   const fullUrl = window.location.href;

  //   // Split the URL into parts
  //   let parts1 = fullUrl.split("//");
  //   if (parts1.length === 2) {
  //     let domainAndPort = parts1[1].split("/")[0]; // Extract domain and port
  //     let domainParts = domainAndPort.split(":"); // Split domain and port
  //     let domain = domainParts[0]; // Extract domain (e.g., "hi-algeria.africa-places.com")
  //     let port = domainParts[1] ? `:${domainParts[1]}` : ""; // Extract port if it exists

  //     let parts2 = domain.split("."); // Split domain into parts (e.g., ["hi-algeria", "africa-places", "com"])

  //     let newDomain = "";

  //     // Check if it's a main domain or subdomain
  //     if (parts2[0] === "www") {
  //       // Main domain case (www.brazil-places.com)
  //       if (value === "www") {
  //         // English: Keep original domain
  //         newDomain = parts2.join(".");
  //       } else {
  //         // Other languages: Replace "www" with language code
  //         newDomain = `${value}-${parts2.slice(1).join(".")}`;
  //       }
  //     } else {
  //       // Subdomain case (algeria.africa-places.com or hi-algeria.africa-places.com)
  //       const subdomainParts = parts2[0].split("-");
  //       const hasLanguageCode =
  //         subdomainParts.length > 1 &&
  //         languages.some((lang) => lang.value === subdomainParts[0]);

  //       if (hasLanguageCode) {
  //         // Case 1: Subdomain already has a language code (hi-algeria)
  //         const remainingSubdomain = subdomainParts.slice(1).join("-"); // "algeria"

  //         if (value === "www") {
  //           // Switch to English: Remove language code
  //           newDomain = [remainingSubdomain, ...parts2.slice(1)].join("."); // "algeria.africa-places.com"
  //         } else {
  //           // Switch to another language: Replace language code
  //           newDomain = [
  //             `${value}-${remainingSubdomain}`,
  //             ...parts2.slice(1),
  //           ].join("."); // "ar-algeria.africa-places.com"
  //         }
  //       } else {
  //         // Case 2: Subdomain has no language code (algeria)
  //         if (value === "www") {
  //           // English: Keep original subdomain
  //           newDomain = parts2.join(".");
  //         } else {
  //           // Add language code to subdomain
  //           newDomain = [`${value}-${parts2[0]}`, ...parts2.slice(1)].join("."); // "hi-algeria.africa-places.com"
  //         }
  //       }
  //     }

  //     // Construct the new URL
  //     const newUrl = `${
  //       parts1[0]
  //     }//${newDomain}${port}${window.location.pathname.replace(/\/+/g, "/")}`;

  //     // Navigate to the new URL
  //     // console.log("newUrl", newUrl);
  //     window.location.href = newUrl; // Uncomment to enable navigation
  //   }
  // };

  const handleValueChange = (value) => {
    setSelectedLang(value);

    const fullUrl = window.location.href;
    let parts1 = fullUrl.split("//");

    if (parts1.length === 2) {
      let domainAndPort = parts1[1].split("/")[0]; // Extract domain and port
      let domainParts = domainAndPort.split(":"); // Split domain and port
      let domain = domainParts[0]; // Extract domain (e.g., "hi.brazil-places.com")
      let port = domainParts[1] ? `:${domainParts[1]}` : ""; // Keep port if present

      let parts2 = domain.split("."); // Split domain into parts (e.g., ["hi", "brazil-places", "com"])
      let newDomain = "";

      const subdomainParts = parts2[0].split("-"); // Extract possible language prefix
      const hasLanguageCode = languages.some(
        (lang) => lang.value === subdomainParts[0]
      );

      if (hasLanguageCode) {
        // If the domain starts with a language code
        const remainingSubdomain = subdomainParts.slice(1).join("-"); // Remove language part

        if (value === "www") {
          if (remainingSubdomain) {
            // CASE: "hi-hong-kong.asia-places.com" → "hong-kong.asia-places.com"
            newDomain = [remainingSubdomain, ...parts2.slice(1)].join(".");
          } else {
            // CASE: "hi.brazil-places.com" → "www.brazil-places.com"
            newDomain = ["www", ...parts2.slice(1)].join(".");
          }
        } else {
          // Replace the existing language with a new language
          newDomain = remainingSubdomain
            ? [`${value}-${remainingSubdomain}`, ...parts2.slice(1)].join(".")
            : [`${value}`, ...parts2.slice(1)].join(".");
        }
      } else {
        // If no language code in subdomain, just add new language prefix
        newDomain =
          value === "www"
            ? parts2.join(".")
            : [`${value}-${parts2[0]}`, ...parts2.slice(1)].join(".");
      }

      // Construct the new URL
      const newUrl = `${
        parts1[0]
      }//${newDomain}${port}${window.location.pathname.replace(/\/+/g, "/")}`;

      // Redirect to the new domain
      window.location.href = newUrl;
    }
  };

  return (
    <>
      <div className="site-header sticky top-0 z-50 w-[100%] md:h-20 bg-white flex justify-between shadow-lg">
        <Link href="/" className="w-1/2">
          <div className="md:h-10 md:mt-5 my-5 ml-10 flex md:w-max items-center logoContainer">
            <Image
              src="/assets/images/logo.png"
              className="pr-2 md:w-20 md:h-10 h-20 w-52 logo"
              width={400}
              height={400}
              alt="image"
              loading="eager"
            />
            <p className="pt-1 md:text-2xl uppercase mx-5 text-xl font-medium logoText">
              <b>{formattedSiteName}</b>
            </p>
          </div>
        </Link>

        <div className="w-1/2 h-10 mt-5 ml-10 hidden md:flex gap-10">
          <div className="flex">
            <div>
              <Link href={`/${formattedCountryName}/top-areas`}>
                <Image
                  src="/assets/img/explore.svg"
                  className=" pr-2 md:w-10 md:h-10 "
                  width={400}
                  height={400}
                  alt="image"
                  loading="eager"
                />
              </Link>
            </div>
            <div className="pt-2">
              <Link href={`/${formattedCountryName}/top-areas`}>
                {localizedStrings && localizedStrings.Index.headerExplore}
              </Link>
            </div>
          </div>
          <div className="flex">
            <div>
              <Link href={`/${formattedCountryName}/top-categories`}>
                <Image
                  src="/assets/img/categories.svg"
                  className=" pr-2 w-10 h-10"
                  width={400}
                  height={400}
                  alt="image"
                  loading="eager"
                />
              </Link>
            </div>
            <div className="pt-2">
              <Link href={`/${formattedCountryName}/top-categories`}>
                {localizedStrings && localizedStrings.Index.headerCategories}
              </Link>
            </div>
          </div>
          <div className="flex">
            <div>
              <Link href="/contact">
                <Image
                  src="/assets/img/contact-us.svg"
                  className=" pr-2 w-10 h-10"
                  width={400}
                  height={400}
                  alt="image"
                  loading="eager"
                />
              </Link>
            </div>
            <div className="pt-2">
              <Link href="/contact">
                {localizedStrings && localizedStrings.Index.headerContact}
              </Link>
            </div>
          </div>
          <div className="flex">
            <select
              value={selectedLang}
              onChange={(e) => {
                handleValueChange(e.target.value);
              }}
              className="border-none"
            >
              {languages.map((lang, index) => (
                <option
                  value={lang.value}
                  className="flex items-center gap-2"
                  key={index}
                >
                  {lang.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="- flex flex-wrap items-center justify-between p-4 ">
          <div className="hidden md:visible">
            <Select
              size="lg"
              label="Select Country"
              onChange={handleValueChange}
              className=""
            >
              {languages.map((lang, index) => (
                <Option
                  value={lang.value}
                  className="flex items-center gap-2"
                  key={index}
                >
                  {lang.label}
                </Option>
              ))}
            </Select>
          </div>

          <div className="md:w-3/12">
            <button
              type="button"
              className="inline-flex items-center p-2 md:w-10 md:h-10 h-16 w-16 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600 drawerBtn"
              onClick={openDrawer}
              aria-label="Open menu"
            >
              <svg
                className="h-16 w-16 drawerIcon"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <Drawer
        open={open}
        onClose={closeDrawer}
        className={open ? " !max-w-[430px]" : "hidden"}
      >
        <div className="h-[300vh] bg-white">
          <div className="mb-2 flex items-center justify-between p-4 w-1/2 ">
            <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-10 w-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </IconButton>
          </div>

          <List>
            <ListItem onClick={closeDrawer}>
              <ListItemPrefix>
                <Image
                  src="/assets/img/explore.svg"
                  className="pr-2 w-10 h-10"
                  width={10}
                  height={10}
                  alt="image"
                />
              </ListItemPrefix>
              <Link
                href={`/${formattedCountryName}/top-areas`}
                className="text-base"
              >
                {localizedStrings && localizedStrings.Index.headerExplore}
              </Link>
            </ListItem>

            <ListItem onClick={closeDrawer}>
              <ListItemPrefix>
                <Image
                  src="/assets/img/categories.svg"
                  className="pr-2 w-10 h-10"
                  width={10}
                  height={10}
                  alt="image"
                />
              </ListItemPrefix>
              <Link
                href={`/${formattedCountryName}/top-categories`}
                className="text-base"
              >
                {localizedStrings && localizedStrings.Index.headerCategories}
              </Link>
            </ListItem>
            <ListItem onClick={closeDrawer}>
              <ListItemPrefix>
                <Image
                  src="/assets/img/contact-us.svg"
                  className="pr-2 w-10 h-10"
                  height={10}
                  width={10}
                  alt="image"
                />
              </ListItemPrefix>
              <Link href="/contact" className="text-base">
                {localizedStrings && localizedStrings.Index.headerContact}
              </Link>
            </ListItem>

            <ListItem>
              <ListItemPrefix>
                <Image
                  src="/assets/img/languages.png"
                  className="pr-2 w-10 h-10"
                  width={10}
                  height={10}
                  alt="image"
                />
              </ListItemPrefix>

              <div className="flex">
                <select
                  value={selectedLang}
                  onChange={(e) => handleValueChange(e.target.value)}
                  className="border-none text-base"
                >
                  {languages.map((lang, index) => (
                    <option
                      value={lang.value}
                      className="flex items-center gap-2 text-sm"
                      key={index}
                    >
                      {lang.label}
                    </option>
                  ))}
                </select>
              </div>
            </ListItem>
          </List>
        </div>
      </Drawer>
    </>
  );
}
