import Link from "next/link";
import React, { useState, useEffect } from "react";
import { getLocals } from "../../../getLocal";
import { hostNameFunction } from "../../../utils/host-function";

export default function Footer({ countryName, countryTranslations }) {
  const [loadTime, setLoadTime] = useState();

  const [localizedStrings, setLocalizedStrings] = useState();

  useEffect(() => {
    const fetchLocalizedStrings = async () => {
      const lang = hostNameFunction() || "en";
      const strings = await getLocals(lang);
      setLocalizedStrings(strings);
    };

    fetchLocalizedStrings();
  }, []);
  useEffect(() => {
    const startTime = performance.now();
    return () => {
      const loadTime = performance.now() - startTime;
      const formattedNumber = loadTime.toFixed(2);
      setLoadTime(formattedNumber);
    };
  }, []);

  return (
    <footer className="bg-blue-500 absolute w-full">
      <div className="flex gap-2 justify-center items-center text-white pb-5 pt-5 footerContainer">
        <Link href="/privacy-policy" className="text-base footerLink">
          {localizedStrings && localizedStrings.Index.footer.ele1}
        </Link>
        <p>|</p>
        <Link href="/terms-and-condition " className="text-base footerLink">
          {localizedStrings && localizedStrings.Index.footer.ele2}
        </Link>
        <p>|</p>
        <Link href="/contact" className="text-base footerLink">
          {localizedStrings && localizedStrings.Index.footer.ele3}
        </Link>
        <p>|</p>
        <Link href="/contact?sub=adv" className="text-base footerLink">
          {localizedStrings && localizedStrings.Index.footer.ele4}
        </Link>
        <p>|</p>
        <Link href="/add-listing" className="text-base footerLink">
          {localizedStrings && localizedStrings.Index.footer.ele5}
        </Link>
      </div>
      <div className="flex items-center justify-center text-white pb-5">
        <p className="text-base">
          {" "}
          {localizedStrings && localizedStrings.Index.footer.ele8} 2024{" "}
          {countryTranslations?.[1]}
        </p>
      </div>
    </footer>
  );
}
